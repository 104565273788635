export default {
	breakpoints: [
		'40em', '56em', '64em', '90em'
	],
	space: [
		0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512
	],
	fontSizes: [
		12, 14, 16, 20, 24, 32, 48, 64, 96
	],
	fonts: {
    body: '"Barlow", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    heading: '"Barlow", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    monospace: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace'
	},
	colors: {
		text: '#000',
		background: '#fff',
		primary: 'rgb(45, 84, 201)',
		headerBg: "#0e1204",
		textColor: "#444852",
    navColor: "333",
    secondary: "#0E1204",
	modes: {
		dark: {
			text: '#fff',
			background: '#0e1204',
			primary: '#0cf',
			postText: '#fff',
      headerBg: "#1a2207",
      textColor: "#eee",
      navColor: "eee",
      secondary: "#fff",
		}
	}
	},
	variants: {
    container: {
      maxWidth: "1220px",
			m: "0 auto",
			p: "0 20px",
    }
	},
}